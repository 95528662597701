
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
import { formatDate, formatTime } from '@/utils/common'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { getAccountProcessList, PlatformPayProcessQueryParams } from '@/api/pay' 
import PayProcessEditDialog from '@/views/pay/PayProcessEditDialog.vue'
import useAreaCascaderProps from '@/utils/composables/useAreaCascaderProps'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    PayProcessEditDialog
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<PlatformPayProcessQueryParams>({accountType: 1})
    const tableData = ref<AnyArray>([])
    const badgeValues = reactive<AnyObject>({})

    const selectedTableData = ref<AnyArray>([])
    const selectCount = computed(() => selectedTableData.value.length)

    const getTableData = async () => {
      const res = await getAccountProcessList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = res.data.data.records
      pagination.total = res.data.data.total
    }
    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
    })

    const onSelectionChange = (selection: any[]) => selectedTableData.value = selection

    const exportRef = ref()
    const payProcessEditDialogRef = ref()

    const cascaderValue = ref<string[]>([])
    const searchInputs = reactive<Inputs>([
      {
        label: '账户类型',
        type: 'select',
        placeholder: '请选择账户类型',
        clearable: false,
        options: [
          { label: '胶合板厂', value: 1 },
          { label: '生产机主', value: 2 }
        ],
        model: toRef(searchData, 'accountType'),
      }, {
        label: '胶合板厂名',
        type: 'input',
        placeholder: '请输入胶合板厂名',
        clearable: true,
        show: () => searchData.accountType == 1,
        model: toRef(searchData, 'jhbcName')
      }, {
        label: '生产机主名',
        type: 'input',
        placeholder: '请输入生产机主名',
        clearable: true,
        show: () => searchData.accountType == 2,
        model: toRef(searchData, 'xqjzName')
      }, {
        label: '区域',
        type: 'cascader',
        placeholder: '请选择区域',
        clearable: true,
        model: cascaderValue,
        props: useAreaCascaderProps(),
        showAllLevels: false,
      }, {
        label: '支付公司',
        type: 'input',
        placeholder: '请输入支付公司',
        clearable: true,
        model: toRef(searchData, 'platformAccountName')
      }
    ])
    watch(cascaderValue, val => {
      if (Array.isArray(val) && val.length > 0)
        searchData.areaCode = val[0]
      else searchData.areaCode = ''
    })
    watch(() => searchData.accountType, () => {
      getTableData()
    })

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          exportRef.value.doExport()
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'name',
        label: '客户名称',
        minWidth: '200px',
      }, {
        prop: 'areaName',
        label: '区域',
        minWidth: '150px',
        align: 'center'
      }, {
        prop: 'address',
        label: '详细地址',
        minWidth: '400px',
        align: 'center'
      }, {
        prop: 'process',
        label: '支付路径',
        minWidth: '600px',
        type: 'render',
        align: 'center',
        render: scope => (
          <div>
            {scope.row.processList.map((process: AnyObject, idx: number) => (
              <>
                <span>{process.bankAccountName}</span>
                <span style="color: #409EFF"> ({process.ratio}%) </span>
                {idx !== scope.row.processList.length-1 && <span> -&gt; </span>}
              </>
            ))}
          </div>
        )
      }, {
        type: 'button',
        label: '操作',
        fixed: 'right',
        buttons: [
          { label: '编辑', onClick: scope => payProcessEditDialogRef.value.show(searchData.accountType, scope.row.accountId, scope.row.processList) }
        ]
      }
    ])

    return {
      getTableData,
      onSelectionChange,
      searchInputs,
      searchButtons,
      tableColumns,
      pagination,
      badgeValues,
      tableData,
      UserRole,
      selectCount,
      exportRef,
      payProcessEditDialogRef
    }
  }
})
