
import { getPlatformBankAccountList, savePayProcess, SavePayProcessData } from "@/api/pay";
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import { Buttons } from "@/types/BasicComponent";
import { ElMessage, ElMessageBox } from "element-plus";
import { computed, defineComponent, reactive, ref } from "vue";
import Draggable from "vuedraggable"

export default defineComponent({
  components: { 
    BasicFormDialog,
    Draggable
  },
  emits: ['on-success'],
  setup(props, { emit }) {
    const modalShow = ref(false)

    const accountId = ref(-1)
    const accountType = ref(-1)
    const processList = ref<AnyArray>([])
    const show = async (type: number, id: number, pList: AnyArray) => {
      accountId.value = id
      accountType.value = type

      processList.value = pList

      modalShow.value = true
    }

    const onClose = () => {
      emit('on-success')
    }
    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    })

    const drag = ref(false)

    const corpSelectDialogShow = ref(false)
    const selectableCorpList = ref<AnyArray>([])
    const onOpenCorpSelect = async () => {
      corpSelectDialogShow.value = true
      const resp = await getPlatformBankAccountList({ pageNo: 1, pageSize: 100 })
      const respData = resp.data.data.records

      selectableCorpList.value = respData.filter((rd: AnyObject) => !processList.value.some(p => p.bpaId == rd.id))
    }

    const onSelectCorp = (item: AnyObject) => {
      processList.value.push({
        bankAccountName: item.bankAccountName,
        bankAccountNumber: item.bankAccountNumber,
        bpaId: item.id
      })
      corpSelectDialogShow.value = false
      onOpenFeeRateSet(item.id)
    }

    const onDeleteProcess = async (bpaId: number) => {
      const idx = processList.value.findIndex(p => p.bpaId == bpaId)
      if (idx >= 0) {
        await ElMessageBox.confirm('是否确认删除该支付路径？', '提示', { type: 'warning' })
        processList.value.splice(idx, 1)
        ElMessage.success('删除成功')
      }
    }

    const feeRateDialogShow = ref(false)
    const feeRateDialogTitle = ref('')
    const currentFeeRate = ref(0)
    const currentBpaId = ref(-1)
    const onOpenFeeRateSet = (bpaId: number) => {
      const process = processList.value.find(p => p.bpaId == bpaId)

      currentBpaId.value = bpaId
      if (process) {
        feeRateDialogShow.value = true
        feeRateDialogTitle.value = process.bankAccountName
        currentFeeRate.value = process.ratio
      }
    }
    const onConfirmFeeRate = () => {
      const process = processList.value.find(p => p.bpaId == currentBpaId.value)

      if (process) {
        process.ratio = currentFeeRate.value
        feeRateDialogShow.value = false
      }
    }
    const onCancelFeeRate = () => {
      processList.value = processList.value.filter(p => !isNaN(Number(p.ratio)))
      feeRateDialogShow.value = false
    }

    const onCommitProcess = async () => {
      if (processList.value.length > 0) {
        // 校验
        let maxFeeRate = processList.value[0].ratio
        for (let process of processList.value) {
          if (Number(process.ratio) > maxFeeRate) {
            ElMessage.warning(`${process.bankAccountName}费率设置错误，不能大于${maxFeeRate}%`)
            return
          }
          maxFeeRate = process.ratio
        }
      }

      const requestData: SavePayProcessData = {
        accountType: accountType.value,
        accountId: accountId.value,
        processList: processList.value.map((p, idx) => {
          return {
            bpaId: p.bpaId,
            ratio: p.ratio,
            priority: idx + 1
          }
        })
      }
      await savePayProcess(requestData)
      ElMessage.success('修改成功')
      emit('on-success')
      modalShow.value = false
    }

    const footerButtons: Buttons = [
      {
        label: '保存',
        type: 'primary',
        onClick: onCommitProcess
      }, {
        label: '取消',
        type: 'default',
        onClick: () => modalShow.value = false
      }
    ]

    return {
      show,
      onClose,
      onOpenCorpSelect,
      onSelectCorp,
      onDeleteProcess,
      onOpenFeeRateSet,
      onConfirmFeeRate,
      onCancelFeeRate,
      onCommitProcess,
      currentFeeRate,
      feeRateDialogTitle,
      feeRateDialogShow,
      modalShow,
      selectableCorpList,
      drag,
      dragOptions,
      processList,
      footerButtons,
      corpSelectDialogShow
    }
  }
})
